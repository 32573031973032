import * as React from 'react';
import styled from 'styled-components';
import { ExternalLink } from '@allenai/varnish/components';

import rowanImg from './images/rowan.jpg';
import ariImg from './images/ari.jpg';
import hannahImg from './images/hannah.jpg';
import yonatanImg from './images/yonatan.jpg';
import aliImg from './images/ali.jpg';
import franziImg from './images/franzi.jpg';
import yejinImg from './images/yejin.jpg';

const authors = [
    {
        name: 'Rowan Zellers',
        url: 'https://rowanzellers.com/',
        img: rowanImg
    },
    {
        name: 'Ari Holtzman',
        url: 'https://ari-holtzman.github.io/',
        img: ariImg
    },
    {
        name: 'Hannah Rashkin',
        url: 'https://homes.cs.washington.edu/~hrashkin/',
        img: hannahImg
    },
    {
        name: 'Yonatan Bisk',
        url: 'http://yonatanbisk.com/',
        img: yonatanImg
    },
    {
        name: 'Ali Farhadi',
        url: 'http://homes.cs.washington.edu/~ali/',
        img: aliImg
    },
    {
        name: 'Franziska Roesner',
        url: 'https://www.franziroesner.com/',
        img: franziImg
    },
    {
        name: 'Yejin Choi',
        url: 'https://homes.cs.washington.edu/~yejin/',
        img: yejinImg
    }
];

export const AuthorList = () => (
    <Section>
        <p>
            The Grover model is developed jointly by
            {" "}<ExternalLink href="https://allenai.org">AI2</ExternalLink>
            {" "}and the
            {" "}<ExternalLink href="https://www.cs.washington.edu/">UW Allen School</ExternalLink>
            {" "}by the following authors:
        </p>
        <List>{authors.map(author => (
            <ListItem key={author.name}>
                <ExternalLink href={author.url}>
                    <ProfileImg src={author.img}
                            width="130" height="130"
                            alt={author.name} />
                    {author.name}
                </ExternalLink>
            </ListItem>
        ))}</List>
    </Section>
);

const Section = styled.section`
    min-width: 100%;
    border-top: 1px solid ${({theme}) => theme.palette.border.main};
    padding: ${({theme}) => `${theme.spacing.xl} 0 0`};
    margin: ${({theme}) => `${theme.spacing.xl} 0 0`};
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    margin: ${({theme}) => `-${theme.spacing.md}`};
    min-width: 100%;

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        display: block;
        margin: 0;
    }
`;

const ListItem = styled.li`
    margin: ${({theme}) => theme.spacing.sm};
    text-align: center;

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        margin-left: 0;
        margin-right: 0;
    }
`;

const ProfileImg = styled.img`
    display: block;
    margin: ${({theme}) => `0 0 ${theme.spacing.md}`};

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        width: 100%;
        height: auto;
    }
`;
