import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { Select } from 'antd';
import {
    Body,
    BodyMicro,
    BodyJumbo,
    DatePicker,
    Input,
    TextArea
} from '@allenai/varnish/components';

import * as examples from '../examples';
import { ask, FieldName } from '../api';
import {
    Row,
    LeftColumn,
    RightColumn,
    Generator,
    InputHint,
    ErrorMessage,
    CopyToClipboard,
    SelectWithSpace
} from '../components';

interface State {
    domain: Loadable,
    date: Loadable,
    authors: Loadable,
    title: Loadable,
    article: Loadable,
    selectedExample: Example,
    error?: ErrorType
};

enum ErrorType {
    API_OVERLOADED, UNKNOWN
}

function doubleUpNewlines(value?: string): string {
    return value ? value.replace(/\n\n/g, "\n").replace(/\n/g, "\n\n") : "";
}

function removeNewlines(value?: string): string {
    return value ? value.replace(/\n+/g, "\n") : "";
}

// convert "2019-01-02" to "January 2nd 2019"
function convertDateToReadable(value?: string): string {
    return value ? moment(value, "YYYY-MM-DD").format("LL") : "";
}

// convert "January 2nd 2019" to "2019-01-02"
function convertDateToSystem(value?: string): string {
    return value ? moment(value, "LL").format("YYYY-MM-DD") : "";
}

// convert "2019-01-02" to moment(for date 2019-01-02)
function convertDateToMoment(value?: string): moment.Moment | undefined {
    return value ? moment(value, "YYYY-MM-DD") : undefined;
}

function withLoadingField(state: State, field: FieldName, isLoading: boolean = true): State {
    switch (field) {
        case FieldName.DOMAIN: {
            const orig = state.domain;
            const domain = { ...orig, isLoading };
            return { ...state, ...{ domain }}
        }
        case FieldName.DATE: {
            const orig = state.date;
            const date = { ...orig, isLoading };
            return { ...state, ...{ date }}
        }
        case FieldName.AUTHORS: {
            const orig = state.authors;
            const authors = { ...orig, isLoading };
            return { ...state, ...{ authors }}
        }
        case FieldName.ARTICLE: {
            const orig = state.article;
            const article = { ...orig, isLoading };
            return { ...state, ...{ article }}
        }
        case FieldName.TITLE: {
            const orig = state.title;
            const title = { ...orig, isLoading };
            return { ...state, ...{ title }}
        }
        default: {
            throw new Error(`Unknown field: ${field}`);
        }
    }
}

function withFieldValue(state: State, field: FieldName, value: string): State {
    switch (field) {
        case FieldName.DOMAIN:
            const domain = state.domain;
            domain.value = value;
            return { ...state, ...{ domain }}
        case FieldName.DATE:
            const date = state.date;
            date.value = convertDateToSystem(value);
            return { ...state, ...{ date }}
        case FieldName.AUTHORS:
            const authors = state.authors;
            authors.value = value;
            return { ...state, ...{ authors }}
        case FieldName.ARTICLE:
            const article = state.article;
            article.value = doubleUpNewlines(value);
            return { ...state, ...{ article }}
        case FieldName.TITLE:
            const title = state.title;
            title.value = value;
            return { ...state, ...{ title }}
        default:
            throw new Error(`Unknown field: ${field}`);
    }
}

function messageFromErrorType(error: ErrorType): string {
    switch (error) {
        case ErrorType.API_OVERLOADED:
            return "Sorry, our servers are overloaded. Please try again in a moment.";
        case ErrorType.UNKNOWN:
        default:
            return "Sorry, something didn't work on our end. Please try again in a moment";
    }
}

interface Loadable {
  value?: string,
  error?: string,
  isLoading?: boolean
}

interface Example {
    domain?: string,
    date?: string,
    authors?: string,
    title?: string,
    article?: string
}

export class GenerateForm extends React.PureComponent<RouteComponentProps, State> {
    resultsRef = React.createRef<HTMLDivElement>();

    constructor(props: RouteComponentProps) {
        super(props);

        this.state = this.exampleToState(examples.fake[0]);
    }

    exampleToState = (example: Example): State => {
        return {
            domain: { value: example.domain },
            date: { value: example.date },
            authors: { value: example.authors },
            title: { value: example.title },
            article: { value: doubleUpNewlines(example.article) },
            selectedExample: example
         };
    }

    handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        this.setState({ domain: { value } });
    }

    handleDateChange = (_: moment.Moment | null, dateString: string) => {
        this.setState({ date: { value: convertDateToSystem(dateString) } });
    }

    handleAuthorsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        this.setState({ authors: { value } });
    }

    handleTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        this.setState({ title: { value } });
    }

    handleArticleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        this.setState({ article: { value } });
    }

    handleExampleChange = (eventValue: any) => {
        const value = eventValue as string;
        const filteredExamples = examples.fake.filter((ex: Example) => ex.title === value);
        this.setState(this.exampleToState(filteredExamples.length ? filteredExamples[0] : examples.fake[0]));
    }

    isLoading() {
        return (
            this.state.domain.isLoading ||
            this.state.date.isLoading ||
            this.state.authors.isLoading ||
            this.state.title.isLoading ||
            this.state.article.isLoading
        );
    }
    generate(target: FieldName) {
        if (!this.isLoading()) {
            this.setState(curState => {
                const values = {
                    title: curState.title.value || "",
                    article: curState.article.value ? removeNewlines(curState.article.value) : "",
                    domain: curState.domain.value || "",
                    date: (curState.date.value ? convertDateToReadable(curState.date.value) : ""),
                    authors: curState.authors.value || ""
                };
                ask({ target, ...values })
                    .then(resp => {
                        // The API returns errors as 200s, so we have to handle
                        // errors here too.
                        if (resp.data.gen === "error") {
                            throw new Error("The server returned an error.");
                        } else {
                            this.setState(curState => {
                                return withLoadingField(
                                    withFieldValue(curState, target, resp.data.gen),
                                    target,
                                    false
                                );
                            });
                        }
                    })
                    .catch(err => {
                        this.setState(curState => {
                            const error = (
                                err.response && err.response.status == 504
                                    ? ErrorType.API_OVERLOADED
                                    : ErrorType.UNKNOWN
                            );
                            return {
                                ...withLoadingField(
                                    curState,
                                    target,
                                    false
                                ),
                                ...{ error }
                            };
                        });
                    });
                return {
                    ...withLoadingField(curState, target),
                    ...{ error: undefined }
                };
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.error ? (
                    <ErrorMessage>{messageFromErrorType(this.state.error)}</ErrorMessage>
                ) : null}
                <Row>
                    <LeftColumn>
                        <ExampleTitle>
                            Examples
                        </ExampleTitle>
                        <SelectWithSpace
                            value={this.state.selectedExample.title}
                            onChange={this.handleExampleChange}>
                            {examples.fake.map(
                                (ex: Example) => <Select.Option key={ex.title} value={ex.title}>{ex.title}</Select.Option>
                                )}
                        </SelectWithSpace>
                        <InputHint>
                            <BodyMicro>Select an example or build an article below</BodyMicro>
                        </InputHint>

                        <InputTitle>
                            Inputs
                        </InputTitle>
                        <Generator inputLabel="Domain"
                            inputHint="Give your article a domain source to write against"
                            onSubmit={() => this.generate(FieldName.DOMAIN) }
                            showLoading={this.state.domain.isLoading}
                            disabled={this.isLoading()}
                            inputCtrl={(<Input
                                maxLength={80}
                                disabled={this.isLoading()}
                                placeholder="Write a domain or generate one"
                                value={this.state.domain.value}
                                onChange={this.handleDomainChange}
                            />)}
                        />
                        <Generator inputLabel="Date"
                            inputHint="Give your article a date to write against"
                            onSubmit={() => this.generate(FieldName.DATE) }
                            showLoading={this.state.date.isLoading}
                            disabled={this.isLoading()}
                            inputCtrl={(<DatePicker
                                allowClear={false}
                                format="LL"
                                disabled={this.isLoading()}
                                placeholder="Write a domain or generate one"
                                value={convertDateToMoment(this.state.date.value)}
                                onChange={this.handleDateChange}
                            />)}
                        />
                        <Generator inputLabel="Authors"
                            inputHint="Give your article one or more authors to write against"
                            onSubmit={() => this.generate(FieldName.AUTHORS) }
                            showLoading={this.state.authors.isLoading}
                            disabled={this.isLoading()}
                            inputCtrl={(<TextArea
                                maxLength={200}
                                autosize={{minRows: 1, maxRows: 5}}
                                disabled={this.isLoading()}
                                placeholder="Write one or more authors or generate one"
                                value={this.state.authors.value}
                                onChange={this.handleAuthorsChange}
                            />)}
                        />
                        <Generator inputLabel="Headline"
                            inputHint="Give your article a headline to write against"
                            onSubmit={() => this.generate(FieldName.TITLE) }
                            showLoading={this.state.title.isLoading}
                            disabled={this.isLoading()}
                            inputCtrl={(<TextArea
                                maxLength={200}
                                autosize={{minRows: 2, maxRows: 5}}
                                disabled={this.isLoading()}
                                placeholder="Write a headline or generate one"
                                value={this.state.title.value}
                                onChange={this.handleTitleChange}
                            />)}
                        />
                        <Generator inputLabel="Article"
                            onSubmit={() => this.generate(FieldName.ARTICLE) }
                            showLoading={this.state.article.isLoading}
                            disabled={this.isLoading()}
                            inputCtrl={(<TextArea
                                maxLength={100000}
                                disabled={this.isLoading()}
                                autosize={{minRows: 5, maxRows: 17}}
                                placeholder="Write an article or generate one"
                                value={this.state.article.value}
                                onChange={this.handleArticleChange}
                            />)}
                        />
                    </LeftColumn>
                    <RightColumn>
                        <FakeTitleArea>
                            <FakeTitle>
                                Fake Article
                            </FakeTitle>
                            <CopyToClipboard targetRef={this.resultsRef}/>
                        </FakeTitleArea>
                        <ResultArea ref={this.resultsRef}>
                            <ResultDomain>
                                <Body>{this.state.domain.value}</Body>
                            </ResultDomain>
                            <ResultHeadline>
                                <BodyJumbo>{this.state.title.value}</BodyJumbo>
                            </ResultHeadline>
                            <ResultDateAuthors>
                                <BodyMicro>
                                    {convertDateToReadable(this.state.date.value)} - {this.state.authors.value}
                                </BodyMicro>
                            </ResultDateAuthors>
                            <ResultArticle>
                                <Body>{doubleUpNewlines(this.state.article.value)}</Body>
                            </ResultArticle>
                        </ResultArea>
                    </RightColumn>
                </Row>
            </React.Fragment>
        );
    }
}

const ResultArea = styled.div``;

const FakeTitleArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

 const FakeTitle = styled.h3`
    margin-right: ${({theme}) => theme.spacing.xs};
    margin-bottom: 0;
`;

const ExampleTitle = styled.h3`
    margin-bottom: ${({theme}) => theme.spacing.xxs};
    display: inline-block;
`;

const InputTitle = styled.h3`
    margin-top: ${({theme}) => theme.spacing.md};
`;

const ResultDomain = styled.div`
    ${Body} {
        font-family: georgia,times new roman,times,serif;
    }
    margin-bottom: ${({theme}) => theme.spacing.xs};
`;

const ResultDateAuthors = styled.div`
    margin-bottom: ${({theme}) => theme.spacing.md};
`;

const ResultHeadline = styled.div`
    ${BodyJumbo} {
        font-family: georgia,times new roman,times,serif;
    }
    margin-bottom: ${({theme}) => theme.spacing.sm};
`;

const ResultArticle = styled.div`
    ${Body} {
        font-family: georgia,times new roman,times,serif;
    }
    white-space: pre-wrap;
`;
