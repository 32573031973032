import * as React from 'react';
import styled from "styled-components";
import { Icon } from 'antd';

import { Button } from '@allenai/varnish/components';

interface Props {
    targetRef: React.RefObject<HTMLDivElement>;
}

export class CopyToClipboard extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                {this.canCopy()
                    ? <Button
                        variant="link"
                        title="Copy to Clipboard"
                        onClick={() => this.copy(this.props.targetRef.current)}>
                            <CopyIcon />
                        </Button>
                    : null}
            </React.Fragment>
        );
    }

    copy(inp: HTMLDivElement | null): void {
        if(inp) {
            const pagelink = "<br/><br/><hr/>Visit <a href='https://grover.allenai.org'>grover.allenai.org</a> to explore AI2's state-of-the-art fake news detector and generator.<hr/>";
            const newdiv = document.createElement("div");

            newdiv.innerHTML = inp.innerHTML + pagelink;
            newdiv.style.position = "absolute";
            newdiv.style.left = "-99999px";
            newdiv.style.pointerEvents = "none";
            document.body.appendChild(newdiv);

            let selection = window.getSelection();
            const range = document.createRange();
            try {
                if(selection) {
                    range.selectNodeContents(newdiv);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand("copy");
                }
            }
            catch (err) {
                alert("please press Ctrl/Cmd+C to copy");
            }

            selection = window.getSelection()
            if(selection) {
                selection.removeAllRanges();
            }
            document.body.removeChild(newdiv);
        }
    }

    canCopy(): boolean {
        return window.getSelection !== undefined
            && document.createRange !== undefined
            && document.execCommand !== undefined;
    }
}

const CopyIcon = styled(Icon).attrs({
    type: "copy"
})`
    font-size: ${({theme}) => theme.typography.h3.fontSize};
`;
