import * as React from "react";
import styled from "styled-components";
import { BodyBold, BodyMicro, TextArea } from '@allenai/varnish/components';
import { Select } from 'antd';

import {
    ErrorMessage,
    InputButton,
    InputHint,
    Loading,
    SelectWithSpace
} from "../components";
import { detect } from "../api";
import * as examples from "../examples";

interface Example {
    article: string;
    title: string;
}

interface State {
    article: string;
    isLoading: boolean;
    resultProbability?: number;
    hasError: boolean;
    validationError?: string;
    selectedExample: string;
}

function textFromProbability(prob: number): JSX.Element {
    if (prob < 0.2) {
        return <div>We are quite sure this was <strong>written by a human</strong>.</div>;
    } else if (prob < 0.5) {
        return <div>We think this was <strong>written by a human</strong> (but we're not sure).</div>;
    } if (prob == 0.5) {
        return <div>We don't know whether this was <strong>written by a machine or a human</strong>.</div>;
    } else if (prob < 0.8) {
        return <div>We think this was <strong>written by a machine</strong> (but we're not sure).</div>;
    } else {
        return <div>We are quite sure this was <strong>written by a machine</strong>.</div>;
    }
}

export class DetectForm extends React.PureComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            article: '',
            isLoading: false,
            hasError: false,
            selectedExample: "-1"
        };
    }
    handleArticleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            article: event.target.value,
            resultProbability: undefined,
            validationError: undefined,
            hasError: false,
            selectedExample: "-1"
        });
    }
    handleExampleChange = (eventValue: any) => {
        const selectedExample = eventValue as string;
        if (selectedExample !== "-1") {
            this.setState({
                selectedExample,
                article: selectedExample,
                resultProbability: undefined,
                validationError: undefined,
                hasError: false
            });
        } else {
            this.setState({
                selectedExample,
                resultProbability: undefined,
                validationError: undefined,
                hasError: false
            });
        }
    }
    detect = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.article.replace(/\n/g, ' ').trim().length < 100) {
            this.setState({
                validationError: "Your text is too short. Please copy and paste a complete article."
            });
        } else {
            this.setState({
                isLoading: true,
                hasError: false,
                resultProbability: undefined,
                validationError: undefined
            }, () => {
                detect(this.state.article.replace(/\n+/g, "\n")).then(resp => {
                    if (resp.data.gen === "error") {
                        throw new Error("The server returned an error.");
                    } else {
                        this.setState({
                            isLoading: false,
                            hasError: false,
                            resultProbability: resp.data.groverprob
                        })
                    }
                }).catch(() => {
                    this.setState({ isLoading: false, hasError: true });
                });
            });
        }
    };
    render() {
        return (
            <React.Fragment>
                {this.state.hasError ? (
                    <ErrorMessage>
                        Sorry, something didn't work on our end. Please try again in a moment.
                    </ErrorMessage>
                ) : null}
                <h3>Examples</h3>
                <MaxWidthSelect
                        value={this.state.selectedExample}
                        onChange={this.handleExampleChange}>
                    <Select.Option value="-1">Select an example</Select.Option>
                    <Select.OptGroup label="Wiki News">
                        {examples.real.map((ex: Example) =>
                            <Select.Option key={`wiki_` + ex.title} value={ex.article}>{ex.title}</Select.Option>)
                        }
                    </Select.OptGroup>
                    <Select.OptGroup label="Grover News">
                        {examples.fake.map((ex: Example) =>
                            <Select.Option key={`grover_` + ex.title} value={ex.article.replace(/\n/g, '\n\n')}>{ex.title}</Select.Option>
                        )}
                    </Select.OptGroup>
                </MaxWidthSelect>
                <InputHint>
                    <BodyMicro>Select an example or copy and paste an article's text below</BodyMicro>
                </InputHint>
                <h3>Article</h3>
                <form onSubmit={this.detect}>
                    <BodyBold>Text:</BodyBold>
                    <MaxWidthTextArea
                        value={this.state.article}
                        onChange={this.handleArticleChange}
                        required={true}
                        placeholder="Add the text of an article to detect whether it's fake or not"
                        maxLength={100000}
                        autosize={{minRows: 8, maxRows: 15}}
                        />
                    {this.state.validationError
                            ? <ValidationError>{this.state.validationError}</ValidationError>
                            : null}
                    <ButtonRow>
                        <InputButton
                                htmlType="submit"
                                disabled={this.state.isLoading}>
                            Detect Fake News
                        </InputButton>
                        {this.state.isLoading
                            ? <LoadingContainer><Loading /></LoadingContainer>
                            : null}
                        {this.state.resultProbability ? (
                            <Result>
                                {textFromProbability(this.state.resultProbability)}
                            </Result>
                        ) : null}
                    </ButtonRow>
                </form>
            </React.Fragment>
        )
    }
}

// as any fixes an old bug (this is an old repo, we don't really care)
// (the real fix would be to upgrade Varnish)
const MaxWidthTextArea = styled(TextArea as any)`
    && {
        display: block;
        max-width: 730px;
    }
`;

const ButtonRow = styled.div`
    display: flex;
    align-items: end;
    margin: ${({theme}) => `${theme.spacing.md} 0 0`};

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        display: block;
    }
`;

const LoadingContainer = styled.div`
    margin-left: ${({theme}) => theme.spacing.md};

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        text-align: center;
        margin: ${({theme}) => `${theme.spacing.md} 0 0`};
    }
`;

const Result = styled.div`
    margin-left: ${({theme}) => theme.spacing.md};
    background: ${({theme}) => theme.palette.background.info};
    border: ${({theme}) => `1px solid ${theme.palette.border.info}`};
    box-sizing: border-box;
    border-radius: ${({theme}) => `${theme.shape.borderRadius}px`};
    padding: ${({theme}) => `${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.xs}`};

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        margin: ${({theme}) => `${theme.spacing.md} 0 0`};
    }
`;

const ValidationError = styled.div`
    color: ${({theme}) => theme.palette.text.error};
    margin: ${({theme}) => `${theme.spacing.xxs} 0 0`};
    font-size: ${({theme}) => theme.typography.bodySmall.fontSize};
`;

const MaxWidthSelect = styled(SelectWithSpace)`
    max-width: 730px;
    width: 100%;
`;
