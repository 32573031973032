/**
 * This file is meant for shared display components that you use throughout
 * your application.
 *
 * Components with a lot of logic, or those that are particularly complicated
 * should probably be put in their own file. This file is meant for the
 * re-usable, simple things used in a lot of different spots in your UI.
 */
import * as React from 'react';
import styled from 'styled-components';
import { Select, Button } from '@allenai/varnish/components';
import * as Antd from 'antd';

export const Loading: React.SFC<{ className?: string }> = ({ className }) => (
    <Antd.Icon type="loading"/>
);

export const RowsAndColumns = styled.div`
    display: flex;

    @media (max-width: ${({theme}) => theme.breakpoints.lg}) {
        display: block;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: ${({theme}) => theme.breakpoints.lg}) {
        display: block;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 0;

    @media (max-width: ${({theme}) => theme.breakpoints.lg}) {
        width: 100%;
    }

`;

export const InputHint = styled.div`
    margin-top: ${({theme}) => theme.spacing.xxs};
    margin-bottom: ${({theme}) => theme.spacing.md};

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        display: none;
    }
`;

export const InputButton = styled(Button).attrs({
    variant: "primary"
})`
   && {
        @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
            && {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }
   }
`;

export const LeftColumn = styled(Column)`
    padding-right: ${({theme}) => theme.spacing.xl};
    border-right: ${({theme}) => `1px solid ${theme.palette.border.main}`};

    @media (max-width: ${({theme}) => theme.breakpoints.lg}) {
        padding-right: 0;
        border-right: 0;
        padding-bottom: ${({theme}) => theme.spacing.xl};
        border-bottom: ${({theme}) => `1px solid ${theme.palette.border.main}`};
    }
`;

export const RightColumn = styled(Column)`
    margin-left: ${({theme}) => theme.spacing.xl};

    @media (max-width: ${({theme}) => theme.breakpoints.lg}) {
        margin-top: ${({theme}) => theme.spacing.xxl};
        margin-left: 0;
    }
`;

export const ErrorMessage = styled.div`
    margin: ${({theme}) => `${theme.spacing.sm} 0 ${theme.spacing.lg}`};
    background: ${({theme}) => theme.palette.background.error};
    border: ${({theme}) => `1px solid ${theme.palette.border.error}`};
    border-radius: ${({theme}) => `${theme.shape.borderRadius}px`};
    padding: ${({theme}) => theme.spacing.lg};
    color: ${({theme}) => theme.palette.text.error};
`;

export const SelectWithSpace = styled(Select)`
    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        margin: ${({theme}) => `0 0 ${theme.spacing.md}`};
    }
`;
