import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Route, NavLink } from 'react-router-dom';
import { ExternalLink } from '@allenai/varnish/components';

import {
    RowsAndColumns,
    Row,
    GenerateForm,
    DetectForm,
    AuthorList
} from '../components';

export default class Home extends React.PureComponent<RouteComponentProps> {
    render() {
        return (
            <React.Fragment>
                <PageContent>
                    <Row>
                        <p>
                            Online disinformation, or fake news intended to deceive, has emerged
                            as a major societal problem. Currently, fake news articles are
                            written by humans, but
                            {" "}<ExternalLink href="https://openai.com/blog/better-language-models/">recently-introduced AI technology</ExternalLink>
                            {" "}based on Neural Networks might enable adversaries to generate
                            fake news. Our goal is to reliably detect this “neural fake news” so
                            that its harm can be minimized.
                        </p>
                        <p>
                            To study and detect neural fake news, we built a model named Grover.
                            Our study presents a surprising result: the best way to detect
                            neural fake news is to use a model that is also a generator. The
                            generator is most familiar with its own habits, quirks, and traits,
                            as well as those from similar AI models, especially those trained on
                            similar data, i.e. publicly available news. Our model, Grover, is a
                            generator that can easily spot its own generated fake news articles,
                            as well as those generated by other AIs. In a challenging setting
                            with limited access to neural fake news articles, Grover obtains
                            over 92% accuracy at telling apart human-written from
                            machine-written news. For more information, please
                            {" "}<ExternalLink href="https://arxiv.org/abs/1905.12616">read our publication</ExternalLink>
                            {" "}as well as
                            {" "}<ExternalLink href="https://medium.com/ai2-blog/counteracting-neural-disinformation-with-grover-6cf6690d463b">our blog post with additional experiments</ExternalLink>. For updates, also
                            {" "}<ExternalLink href="https://rowanzellers.com/grover/">check out our project page.</ExternalLink>
                        </p>
                        <p style={{ marginBottom: 0 }}>
                            Here, we demonstrate how Grover can generate a realistic-looking
                            fake news article, and then detect that it was AI-generated.
                        </p>
                        <BulletedList>
                            <li>
                                To generate a fake news article with Grover, use the
                                ‘Generate’ tab. Fill in some article pieces, and press
                                ‘Generate’ next to the piece you would like to generate.
                                Grover will generate that piece based on the data provided.
                                For instance, if the domain is “nytimes.com”, clicking
                                ‘Generate’ for the Article will generate a fake article body
                                as if it were written for the New York Times.
                            </li>
                            <li>
                                To detect whether an article was written by Grover or a
                                human, use the ‘Detect’ tab. Fill in the input field with
                                article text, and click ‘Detect Fake News.’
                            </li>
                        </BulletedList>
                        <p>
                            Note that, even if Grover fails to detect a given piece as fake, our
                            findings suggest that releasing many such articles taken together
                            would be relatively easy to spot. Thus, if a source of Neural Fake
                            News disseminates a large number of articles, Grover will be
                            increasingly capable of spotting these articles as malicious.
                        </p>
                        <Message>
                            This demo is a prototype. It might take upwards of 30 seconds for Grover to finish generating or detecting, depending on how many people are using the demo right now.
                        </Message>
                        <Tabs>
                            <Tab to="/" exact>Generate</Tab>
                            <Tab to="/detect" exact>Detect</Tab>
                        </Tabs>
                    </Row>
                    <Route path="/" exact component={GenerateForm} />
                    <Route path="/detect" exact component={DetectForm} />
                    <Row>
                        <AuthorList />
                    </Row>
                </PageContent>
            </React.Fragment>
        )
    }
}

const BulletedList = styled.ul`
    margin: ${({theme}) => `0 0 ${theme.spacing.lg}`};
`;

const PageContent = styled(RowsAndColumns)`
    display: flex;
    flex-direction: column;
`;

const Message = styled.div`
    margin: ${({theme}) => `${theme.spacing.sm} 0 ${theme.spacing.lg}`};
    background: ${({theme}) => theme.palette.background.info};
    border: ${({theme}) => `1px solid ${theme.palette.border.info}`};
    border-radius: ${({theme}) => `${theme.shape.borderRadius}px`};
    padding: ${({theme}) => theme.spacing.lg};
`;

const Tabs = styled.nav`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${({theme}) => theme.palette.border.main};
    width: 100%;
    margin-bottom: ${({theme}) => theme.spacing.lg};
`

const Tab = styled(NavLink)`
    padding: ${({theme}) => `${theme.spacing.md} ${theme.spacing.xl}`};
    color: ${({theme}) => theme.palette.primary.main};
    text-decoration: none;
    font-size: ${({theme}) => theme.typography.h4.fontSize};
    font-weight: 900;
    margin-bottom: -1px;
    border-bottom: ${({theme}) => `${theme.spacing.xxs} solid ${theme.palette.common.transparent}`};

    &.active {
        border-bottom-color: ${({theme}) => theme.palette.primary.main};
    }

    &:not(.active):hover {
        color: ${({theme}) => theme.palette.primary.veryDark};
    }
`;
