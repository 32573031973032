/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import * as React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route } from 'react-router-dom';
import {
    Body,
    ExternalLink,
    Footer,
    Header,
    Layout,
    Content,
    HeaderTitle,
    HeaderSubTitle
} from '@allenai/varnish/components';

import Home from './pages/Home';

export default class App extends React.PureComponent {
    goToAI2Home = (event: React.MouseEvent) => {
        if ((event.target as HTMLElement).tagName.toUpperCase() === 'IMG') {
            window.open('https://allenai.org');
        }
    };
    render() {
        return (
            <BrowserRouter>
                <Route path="/">
                    <Layout bgcolor="white">
                        <Header>
                            <HeaderTitle>Grover</HeaderTitle>
                            <div>&nbsp;&mdash;&nbsp;</div>
                            <HeaderSubTitle>A State-of-the-Art Defense against Neural Fake News</HeaderSubTitle>
                        </Header>
                        <Content>
                            <Route path='/' component={Home} />
                        </Content>
                        <Footer>
                            <Body>
                                Demo built by the
                                {' '}<ExternalLink contrast={true} href="https://allenai.org">Allen Institute for Artificial Intelligence (AI2)</ExternalLink>
                                {' '}| <ExternalLink contrast={true} href="https://allenai.org/privacy-policy.html">Privacy Policy</ExternalLink>
                                {' '}| <ExternalLink contrast={true} href="https://allenai.org/terms.html">Terms of Use</ExternalLink>
                            </Body>
                        </Footer>
                    </Layout>
                </Route>
            </BrowserRouter>
        );
    }
}
