import axios, { AxiosPromise } from 'axios';

interface DetectResponse {
    groverprob: number;
    // The API returns "error" here when there's an issue
    gen: string;
}

export function detect(article: string): AxiosPromise<DetectResponse> {
    return axios.post('https://discriminate.grover.allenai.org/api/disc', {
        article,
        domain: '',
        date: '',
        authors: '',
        title: '',
        target: 'discrimination'
    });
}
