import axios, { AxiosPromise } from 'axios';

export enum FieldName {
    DOMAIN = "domain",
    DATE = "date",
    AUTHORS = "authors",
    ARTICLE = "article",
    TITLE = "title"
}

interface AskRequest {
    domain: string;
    date: string;
    authors: string;
    article: string;
    title: string;
    target: FieldName;
}

interface AskResponse {
    gen: string;
}

export function ask(request: AskRequest): AxiosPromise<AskResponse> {
    return axios.post('https://api.grover.allenai.org/api/ask', request);
}
