import * as React from 'react';
import styled from 'styled-components';
import { Key } from 'ts-keycode-enum';
import { BodyBold, BodyMicro } from '@allenai/varnish/components';

import { Loading, InputHint, InputButton } from './shared'

interface Props {
    inputLabel?: string,
    inputHint?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onSubmit?: () => void
    showLoading?: boolean,
    inputCtrl: React.ReactNode,
    disabled?: boolean
}

export class Generator extends React.PureComponent<Props> {
    onKeyDown = (event: React.KeyboardEvent) => {
        if (event.which === Key.Enter && this.props.onSubmit) {
            event.preventDefault();
            this.props.onSubmit();
        }
    };
    onClick = () => {
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }
    render() {
        const {
            inputLabel, disabled, showLoading, inputHint, inputCtrl
        } = this.props;
        return (
            <React.Fragment>
                <BodyBold>
                    {inputLabel}
                </BodyBold>
                <RowFlex>
                    {inputCtrl}
                    {!showLoading
                        ? <StyledInputButton
                                disabled={disabled}
                                onKeyDown={this.onKeyDown}
                                onClick={this.onClick}>Generate</StyledInputButton>
                        : null }
                    {showLoading
                        ? <LoadingContainer><Loading /></LoadingContainer>
                        : null}
                </RowFlex>
                <InputHint>
                    <BodyMicro>{inputHint}</BodyMicro>
                </InputHint>
            </React.Fragment>
        );
    }
}

const RowFlex = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        display: block;
        margin: ${({theme}) => `0 0 ${theme.spacing.md}`};
    }
`;

const LoadingContainer = styled.div`
    height: 36px;
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${({theme}) => theme.spacing.md};

    @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
        margin: ${({theme}) => `${theme.spacing.md} 0 ${theme.spacing.xs} 0`};
        width: 100%;
    }
`;

const StyledInputButton = styled(InputButton)`
    && {
        margin-left: ${({theme}) => theme.spacing.md};
        height: min-content;

        @media (max-width: ${({theme}) => theme.breakpoints.xs}) {
            && {
                margin: ${({theme}) => `${theme.spacing.md} 0 ${theme.spacing.xs} 0`};
            }
        }
    }
`;
